import _ from 'lodash';
import getPersonaConfig from './persona';
import veronica_thumbnail from '../../assets/images/veronica_thumbnail_4.png';

let veronicaStateChangesSinceCum = 0;

const getVeronica = (isLoggedIn) => ({
  personaConfig: getPersonaConfig(isLoggedIn),
  thumbnail: veronica_thumbnail,
  defaultState: {
    isVeronicaNaked: false,
    position: 'sitting',
    setting: 'hotel_room',
    cumLocations: []
  },
  handleAction: (prevState, detail) => {
    const newVeronicaState = { ...prevState };
  
    if (detail.take_off_veronica_clothes && !prevState.isVeronicaNaked) {
      newVeronicaState.isVeronicaNaked = true;
    }

    if (detail.position && detail.position !== prevState.position) {
      newVeronicaState.position = detail.position;
    }

    if (detail.change_setting && detail.change_setting !== prevState.setting) {
      newVeronicaState.setting = detail.change_setting;
    }

    if (detail.cum_location && !_.includes(prevState.cumLocations, detail.cum_location)) {
      newVeronicaState.cumLocations = [...newVeronicaState.cumLocations, detail.cum_location];
    }

    if (
      newVeronicaState.isVeronicaNaked !== prevState.isVeronicaNaked ||
      newVeronicaState.position !== prevState.position ||
      newVeronicaState.setting !== prevState.setting ||
      newVeronicaState.cumLocations.length > prevState.cumLocations.length
    ) {
      veronicaStateChangesSinceCum++;
      if (veronicaStateChangesSinceCum > 2 && !(newVeronicaState.cumLocations.length > prevState.cumLocations.length)) {
        newVeronicaState.cumLocations = [];
        veronicaStateChangesSinceCum = 0;
      }
      return newVeronicaState;
    }

    return prevState;
  },
  generateSceneSilenceReaction: (sceneState) => {
    let clothingStateDescription = sceneState.isVeronicaNaked ? `I'm showing him all of me` : `I still have my clothes on. He hasn't earned my body yet.`;
    let settingPrompt =
      sceneState.setting === 'play_room' ? `in a kinky sex room` :
      sceneState.setting === 'hotel_room' ? `in a hotel room` :''

    let positionStateDescription =
      sceneState.position === 'sitting' ? `I'm sitting in my chair` :
      sceneState.position === 'titfuck' ? `He's been such a good boy, he's earned a treat. I have his dick between my boobs` :
      sceneState.position === 'giving_handjob' ? `He's been such a good boy, he's earned a big treat.I'm giving him a handjob` :
      sceneState.position === 'giving_blowjob' ? `He's been a very very good boy, he's earned an extra big treat. I'm giving him a blowjob` :
      sceneState.position === "show_pussy_frontal" ? `He's followed my instructions. I'm showing him my pussy` :
      sceneState.position === "show_pussy_closeup" ? `He's done a good job following my instructions. I'm showing him a closeup my pussy` :
      sceneState.position === "show_pussy_frontal_legs_up" ? `I'm showing him my pussy with my legs up` :
      sceneState.position === "show_pussy_from_behind" ? `My good boy has earned a good view. I'm showing him my pussy from behind` :
      sceneState.position === "sex_reverse_cowgirl" ? `I've tamed my good boy, and I'm using him for my pleasure. I'm having sex with him in reverse cowgirl position, riding him` :
      sceneState.position === "face_closeup" ? `my face is close up to his` :
      sceneState.position === 'sex_missionary' ? `I've tamed my good boy, and I'm using him for my pleasure. we're having sex in missionary position` :
      sceneState.position === 'sex_doggystyle' ? `I've tamed my good boy, and I'm using him for my pleasure. we're having sex in doggystyle position` :
      sceneState.position === 'show_butt' ? `He's earned a little treat. I'm standing in front of him, showing him my juicy ass` :
      ''
      return `I should keep the conversation going, continuing the current thread and keeping in mind that ${clothingStateDescription} and ${positionStateDescription} and we're ${settingPrompt}`;
  }
});

export default getVeronica;