import _ from 'lodash';
import getPersonaConfig from './persona';
import carrie_thumbnail from '../../assets/images/carrie_thumbnail_4.png';

let carrieStateChangesSinceCum = 0;

const getCarrie = (isLoggedIn) => ({
  personaConfig: getPersonaConfig(isLoggedIn),
  thumbnail: carrie_thumbnail,
  defaultState: {
    isCarrieNaked: false,
    position: 'sitting',
    setting: 'carries_room',
    cumLocations: []
  },
  handleAction: (prevState, detail) => {
    const newCarrieState = { ...prevState };
  
    if (detail.take_off_carrie_clothes && !prevState.isCarrieNaked) {
      newCarrieState.isCarrieNaked = true;
    }

    if (detail.position && detail.position !== prevState.position) {
      newCarrieState.position = detail.position;
    }

    if (detail.change_setting && detail.change_setting !== prevState.setting) {
      newCarrieState.setting = detail.change_setting;
    }

    if (detail.cum_location && !_.includes(prevState.cumLocations, detail.cum_location)) {
      newCarrieState.cumLocations = [...newCarrieState.cumLocations, detail.cum_location];
    }

    if (
      newCarrieState.isCarrieNaked !== prevState.isCarrieNaked ||
      newCarrieState.position !== prevState.position ||
      newCarrieState.setting !== prevState.setting ||
      newCarrieState.cumLocations.length > prevState.cumLocations.length
    ) {
      carrieStateChangesSinceCum++;
      if (carrieStateChangesSinceCum > 2 && !(newCarrieState.cumLocations.length > prevState.cumLocations.length)) {
        newCarrieState.cumLocations = [];
        carrieStateChangesSinceCum = 0;
      }
      return newCarrieState;
    }

    return prevState;
  },
  generateSceneSilenceReaction: (sceneState) => {
    let clothingStateDescription = sceneState.isAshleyNaked ? `I'm naked` : `I still have my clothes on`;
    let settingPrompt =
      sceneState.setting === 'apartment_kitchen' ? `in my cute little kitchen` :
      sceneState.setting === 'carries_room' ? `in my gamer-girl cave` : ''

    let positionStateDescription =
      sceneState.position === 'sitting' ? `I'm sitting in my chair` :
      sceneState.position === 'titfuck' ? `I have his dick between my boobs` :
      sceneState.position === 'giving_handjob' ? `I'm giving him a handjob` :
      sceneState.position === 'giving_blowjob' ? `I'm giving him a blowjob` :
      sceneState.position === "show_pussy_frontal" ? `I'm showing him my pussy` :
      sceneState.position === "show_pussy_closeup" ? `I'm showing him a closeup my pussy` :
      sceneState.position === "show_pussy_frontal_legs_up" ? `I'm showing him my pussy with my legs up` :
      sceneState.position === "show_pussy_from_behind" ? `I'm showing him my pussy from behind` :
      sceneState.position === "sex_reverse_cowgirl" ? `I'm having sex with him in reverse cowgirl position, riding him` :
      sceneState.position === "face_closeup" ? `my face is close up to his` :
      sceneState.position === 'sex_missionary' ? `we're having sex in missionary position` :
      sceneState.position === 'sex_doggystyle' ? `we're having sex in doggystyle position` :
      sceneState.position === 'show_butt' ? `I'm standing in front of him, showing him my butt` :
      ''
      return `I should keep the conversation going, continuing the current thread and keeping in mind that ${clothingStateDescription} and ${positionStateDescription} and we're ${settingPrompt}`;
  }
});

export default getCarrie;