import getPrompt from './prompt';
import getActions from './actions';

const getConfig = (isLoggedIn) => ({
  initialMessage: 'So, what brings you to my office today?',
  prompt: getPrompt(isLoggedIn),
  actions: getActions(isLoggedIn),
  ttsConfig: {
    voiceId: '8031621c-7679-4e30-9dde-a9c5ffe15cb9'
  },
  llmConfig: {
    llm: 'llama-3-1-70b',
    temperature: 0.9,
    repetitionPenalty: 0.5,
  },
  interjections: {
    enabled: true,
    minWait: 5000,
    maxWait: 7500,
    thought: `There was just a pause; I should keep the conversation going.`
  },
  isLightning: true,
  limitReachedMessage: isLoggedIn ? `That's all for our time today. Upgrade your plan now so we can continue the physical exam.` : `You ought to create an account so I can examine your more... intimate areas.`
});

export default getConfig;

