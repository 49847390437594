import { motion } from 'framer-motion';
import RegularButton from '../buttons/RegularButton';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import useUserStore from '../../stores/userStore';
import { openModal } from '../../stores/modalStore';
import { shallow } from 'zustand/shallow';
import { analytics } from '../../util/analytics';
import { useEffect } from 'react';

const Gallery = ({ scenes, selectedScene, setSelectedScene, isPersonaLoading }) => {
  const isLimitReached = useStoreWithEqualityFn(useUserStore, state => state.isLimitReached, shallow);
  const existingScenes = Object.keys(scenes);
  const totalScenes = 12;
  const comingSoonScenes = Array.from({ length: Math.max(0, totalScenes - existingScenes.length) }, (_, i) => `coming_soon_${i + 1}`);
  const allScenes = [...existingScenes, ...comingSoonScenes];

  useEffect(() => {
    analytics.page('Gallery');
  }, []);

  const handleSceneSelection = (scene) => {
    analytics.track('Clicked Scene', { scene, isLimitReached });
    if (isLimitReached) {
      openModal('upgradePlan');
    } else {
      setSelectedScene(scene);
    }
  };

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-between bg-main-bg bg-opacity-50 z-10 overflow-y-auto py-16">
      <div className="flex-grow flex items-center justify-center">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 px-6 md:px-10 py-10 mt-8 md:mt-20">
          {allScenes.map((scene) => (
            <motion.div
              key={scene}
              className="bg-zinc-900 p-8 rounded-xl shadow-xl text-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 w-full"
              initial={{ opacity: 1 }}
              animate={{
                opacity: selectedScene && selectedScene !== scene ? 0 : 1,
                transition: { duration: 0.5 }
              }}
            >
              <p className={`text-3xl font-bold mb-6 capitalize tracking-wide ${scene.startsWith('coming_soon') ? 'text-gray-500 italic' : 'text-white'}`}>
                {scene.startsWith('coming_soon') ? 'Coming Soon' : scene.replace('_', ' ')}
              </p>
              <div className="relative mb-6 w-full h-56">
                {scene.startsWith('coming_soon') ? (
                  <div className="w-full h-full flex items-center justify-center text-7xl bg-zinc-800 rounded-lg opacity-50">
                    🫦
                  </div>
                ) : (
                  <img 
                    src={scenes[scene].thumbnail} 
                    alt={`${scene.replace('_', ' ')} thumbnail`}
                    className="w-full h-full object-cover rounded-lg shadow-md"
                  />
                )}
                <div className="absolute inset-0 bg-gradient-to-t from-zinc-900 to-transparent rounded-lg"></div>
              </div>
              {scene.startsWith('coming_soon') ? (
                <div className="opacity-50 cursor-not-allowed">
                  <RegularButton disabled className="bg-gray-500 text-gray-300 w-full text-lg py-3">
                    <span>Coming Soon</span>
                  </RegularButton>
                </div>
              ) : (
                <RegularButton
                  onClick={() => handleSceneSelection(scene)}
                  className="w-full text-lg py-3"
                >
                  <span>Start Date</span>
                  {isPersonaLoading && selectedScene === scene && (
                    <div className="ml-3">
                      <svg
                        className="w-6 h-6 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </div>
                  )}
                </RegularButton>
              )}
            </motion.div>
          ))}
        </div>
      </div>
      <motion.p 
        className="text-gray-500 text-center mt-6 mb-10 px-6 text-lg"
        initial={{ opacity: 1 }}
        animate={{
          opacity: selectedScene ? 0 : 1,
          transition: { duration: 0.5 }
        }}
      >
        More dates coming soon. Request new dates in our{' '}
        <a href="https://discord.gg/MRfQfzNJvR" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-500">
          discord
        </a>{' '}
        or on{' '}
        <a href="https://x.com/DateNightAI" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-500">
          X / Twitter
        </a>.
      </motion.p>
      <div className="fixed bottom-0 left-0 right-0 h-48 bg-gradient-to-t from-main-bg to-transparent pointer-events-none"></div>
    </div>
  );
};

export default Gallery;
