import _ from 'lodash';
import getPersonaConfig from './persona';
import bethany_thumbnail from '../../assets/images/bethany_thumbnail_4.png';

let bethanyStateChangesSinceCum = 0;

const getBethany = (isLoggedIn) => ({
  personaConfig: getPersonaConfig(isLoggedIn),
  thumbnail: bethany_thumbnail,
  defaultState: {
    isBethanyNaked: false,
    position: 'sitting',
    setting: 'kitchen',
    cumLocations: []
  },
  handleAction: (prevState, detail) => {
    const newBethanyState = { ...prevState };
  
    if (detail.take_off_bethany_clothes && !prevState.isBethanyNaked) {
      newBethanyState.isBethanyNaked = true;
    }

    if (detail.position && detail.position !== prevState.position) {
      newBethanyState.position = detail.position;
    }

    if (detail.change_setting && detail.change_setting !== prevState.setting) {
      newBethanyState.setting = detail.change_setting;
    }

    if (detail.cum_location && !_.includes(prevState.cumLocations, detail.cum_location)) {
      newBethanyState.cumLocations = [...newBethanyState.cumLocations, detail.cum_location];
    }

    if (
      newBethanyState.isBethanyNaked !== prevState.isBethanyNaked ||
      newBethanyState.position !== prevState.position ||
      newBethanyState.setting !== prevState.setting ||
      newBethanyState.cumLocations.length > prevState.cumLocations.length
    ) {
      bethanyStateChangesSinceCum++;
      if (bethanyStateChangesSinceCum > 2 && !(newBethanyState.cumLocations.length > prevState.cumLocations.length)) {
        newBethanyState.cumLocations = [];
        bethanyStateChangesSinceCum = 0;
      }
      return newBethanyState;
    }

    return prevState;
  },
  generateSceneSilenceReaction: (sceneState) => {
    let clothingStateDescription = sceneState.isBethanyNaked ? `I'm naked` : `I still have my clothes on`;
    let settingPrompt =
      sceneState.setting === 'kitchen' ? `in a cozy home kitchen` :
      sceneState.setting === 'bethanys_bedroom' ? `in my comfy bedroom` : ''

    let positionStateDescription =
      sceneState.position === 'sitting' ? `I'm sitting in my chair` :
      sceneState.position === 'titfuck' ? `I have his dick between my boobs` :
      sceneState.position === 'giving_handjob' ? `I'm giving him a handjob` :
      sceneState.position === 'giving_blowjob' ? `I'm giving him a blowjob` :
      sceneState.position === "show_pussy_frontal" ? `I'm showing him my pussy` :
      sceneState.position === "show_pussy_closeup" ? `I'm showing him a closeup my pussy` :
      sceneState.position === "show_pussy_frontal_legs_up" ? `I'm showing him my pussy with my legs up` :
      sceneState.position === "show_pussy_from_behind" ? `I'm showing him my pussy from behind` :
      sceneState.position === "sex_reverse_cowgirl" ? `I'm having sex with him in reverse cowgirl position, riding him` :
      sceneState.position === "face_closeup" ? `my face is close up to his` :
      sceneState.position === 'sex_missionary' ? `we're having sex in missionary position` :
      sceneState.position === 'sex_doggystyle' ? `we're having sex in doggystyle position` :
      sceneState.position === 'show_butt' ? `I'm standing in front of him, showing him my butt` :
      ''
      return `I should keep the conversation going, continuing the current thread and keeping in mind that ${clothingStateDescription} and ${positionStateDescription} and we're ${settingPrompt}`;
  }
});

export default getBethany;