import getPrompt from './prompt';
import getActions from './actions';

const getConfig = (isLoggedIn) => ({
  initialMessage: 'I could really use a break from studying...',
  prompt: getPrompt(isLoggedIn),
  actions: getActions(isLoggedIn),
  ttsConfig: {
    voiceId: 'ddfd4561-0164-4513-8d69-6bf634a3a5de'
  },
  llmConfig: {
    llm: 'llama-3-1-70b',
    temperature: 0.9,
    repetitionPenalty: 0.5,
  },
  interjections: {
    enabled: true,
    minWait: 5000,
    maxWait: 7500,
    thought: `There was just a pause; I should keep the conversation going.`
  },
  isLightning: true,
  limitReachedMessage: isLoggedIn ? `We have to stop now. We can keep going if you upgrade your plan.` : `Y'know, if you create an account, we might be able to get to know each other a little better...`
});

export default getConfig;
