import useUserStore from '../stores/userStore';

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || "";

// Get auth headers for all API requests
const getAuthHeaders = () => {
  const headers = {
    'Content-Type': 'application/json'
  };

  const accessToken = useUserStore.getState().accessToken;
  const visitorId = useUserStore.getState().visitorId;

  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  } else if (visitorId) {
    headers['x-visitor-id'] = visitorId;
  }

  return headers;
};

export const generateImage = async (selectedScene, currentSceneState) => {
  const apiURL = `${API_DOMAIN}/generateImage`;

  const response = await fetch(apiURL, {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({ scene: selectedScene, sceneState: currentSceneState }),
  });
  
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const imageData = await response.json();

  if (imageData.error) {
    throw new Error(imageData.error);
  }
  
  return {
    image: `data:image/jpeg;base64,${imageData.image}`,
    imageId: imageData.imageId
  };
};