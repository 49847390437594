import getAshley from './ashley';
import getBethany from './bethany';
import getCarrie from './carrie';
import getNicole from './nicole';
import getPriya from './priya';
import getVeronica from './veronica';

export const getScenes = (isLoggedIn) => ({
  ashley: getAshley(isLoggedIn),
  bethany: getBethany(isLoggedIn),
  carrie: getCarrie(isLoggedIn),
  nicole: getNicole(isLoggedIn),
  priya: getPriya(isLoggedIn),
  veronica: getVeronica(isLoggedIn)
});
