import React, { useState, useRef, useEffect } from 'react';
import useUserStore from '../../stores/userStore';
import { useAuth } from '../../contexts/AuthContext';
import Spinner from '../spinner';
import { closeModal } from '../../stores/modalStore';
import { useLocation } from 'react-router-dom';
import { analytics } from '../../util/analytics';
import { useAuth0 } from '@auth0/auth0-react';
import SignUpButton from '../buttons/SignUpButton';

const UpgradePlanModal = () => {
  const { user, upgradePlan, isLimitReached } = useUserStore();
  const { isAuth0Loading } = useAuth();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [loadingPlan, setLoadingPlan] = useState(null);
  const [error, setError] = useState(null);
  const [showCancelView, setShowCancelView] = useState(false);
  const [canClose, setCanClose] = useState(false);
  const [countdown, setCountdown] = useState(15);
  const modalRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    analytics.track('Opened Upgrade Plan Modal');
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && canClose) {
        analytics.track('Closed Upgrade Plan Modal');
        closeModal('upgradePlan');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [canClose]);

  useEffect(() => {
    const isInStory = location.pathname.includes('/story');
    if (isInStory && isLimitReached) {
      const timer = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(timer);
            setCanClose(true);
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setCanClose(true);
      setCountdown(0);
    }
  }, [location, isLimitReached]);

  const handleUpgrade = async (plan) => {
    analytics.track('Clicked Upgrade Plan', { plan });
    setLoadingPlan(plan);
    setError(null);
    try {
      const result = await upgradePlan(plan);
      if (result.redirectURL) {
        window.location.href = result.redirectURL;
      } else {
        // show an error
      }
    } catch (error) {
      setError('Failed to upgrade plan. Please try again.');
    } finally {
      setLoadingPlan(null);
    }
  };

  const isPlanDisabled = (plan) => user?.plan === plan;

  const isPaidPlan = user?.plan !== 'free_trial';

  const handleCancelClick = () => {
    analytics.track('Clicked Cancel Subscription');
    setShowCancelView(true);
  };

  const handleCloseModal = () => {
    if (canClose) {
      analytics.track('Closed Upgrade Plan Modal');
      closeModal('upgradePlan');
    }
  };

  if (isAuth0Loading) {
    return <Spinner />;
  }

  if (!isAuthenticated) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-main-bg bg-opacity-75">
        <div ref={modalRef} className="bg-zinc-900 text-gray-300 rounded-xl p-8 shadow-xl max-w-md w-full transform transition-all">
          <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent">Sign In To Continue</h2>
          
          <div className="mb-8 p-6 bg-zinc-800 rounded-lg border border-zinc-700">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <span className="mr-2">✨</span>
              In 30 seconds, you'll get:
            </h3>
            <ul className="space-y-3">
              <li className="flex items-center">
                <span className="text-indigo-400 mr-2">•</span>
                <span>50 free credits instantly</span>
              </li>
              <li className="flex items-center">
                <span className="text-indigo-400 mr-2">•</span>
                <span>Up to $5 in bonus credits through referrals</span>
              </li>
              <li className="flex items-center">
                <span className="text-indigo-400 mr-2">•</span>
                <span>Full adult access</span>
              </li>
            </ul>
          </div>
          
          <div className="flex justify-between items-center space-x-4">
            <button
              className={`px-5 py-2.5 ${
                canClose 
                  ? 'bg-zinc-700 hover:bg-zinc-600 text-white' 
                  : 'bg-zinc-800 text-zinc-500 cursor-not-allowed'
              } rounded-lg transition-all duration-200 font-medium`}
              onClick={handleCloseModal}
              disabled={!canClose}
            >
              {canClose ? 'Maybe Later' : `Please wait ${countdown}s`}
            </button>
            <button
              className="px-6 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white font-medium rounded-lg hover:from-indigo-500 hover:to-purple-500 transition-all duration-200 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-zinc-900"
              onClick={() => loginWithRedirect()}
            >
              Create Free Account
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (showCancelView) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-main-bg bg-opacity-75">
        <div ref={modalRef} className="bg-zinc-900 text-gray-300 rounded-xl p-8 shadow-xl max-w-md w-full transform transition-all">
          <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent">Cancel Subscription</h2>
          <div className="mb-8 p-6 bg-zinc-800 rounded-lg border border-zinc-700">
            <p className="mb-4">To cancel or update your subscription, use your Epoch member id and Epoch email with Epoch:</p>
            <div className="space-y-3">
              <p className="flex items-center">
                <span className="text-indigo-400 mr-2">•</span>
                <strong>Epoch Member ID:</strong> 
                <span className="ml-2">{user.epoch_member_id}</span>
              </p>
              <p className="flex items-center">
                <span className="text-indigo-400 mr-2">•</span>
                <strong>Epoch Email:</strong>
                <span className="ml-2">{user.epoch_email}</span>
              </p>
            </div>
          </div>
          
          <div className="flex justify-between items-center space-x-4">
            <button
              className="px-5 py-2.5 bg-zinc-700 hover:bg-zinc-600 text-white rounded-lg transition-all duration-200 font-medium"
              onClick={() => setShowCancelView(false)}
            >
              Back
            </button>
            <a 
              href="https://epoch.com" 
              target="_blank" 
              rel="noopener noreferrer"
              className="px-6 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white font-medium rounded-lg hover:from-indigo-500 hover:to-purple-500 transition-all duration-200 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-zinc-900"
            >
              Go to Epoch.com
            </a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-main-bg bg-opacity-75">
      <div ref={modalRef} className="bg-zinc-900 text-gray-300 rounded-xl p-8 shadow-xl max-w-md w-full transform transition-all">
        <h2 className="text-3xl font-bold mb-6 bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent">Upgrade to Continue</h2>
        
        <div className="mb-6 space-y-2">
          <p className="text-sm italic text-gray-400 flex items-center">
            <span className="text-indigo-400 mr-2">•</span>
            10 credits ≈ 1 minute of conversation
          </p>
          <p className="text-sm italic text-gray-400 flex items-center">
            <span className="text-indigo-400 mr-2">•</span>
            Remaining credits carry over when changing plans
          </p>
          <p className="text-sm italic text-gray-400 flex items-center">
            <span className="text-indigo-400 mr-2">•</span>
            New monthly charges apply. Change anytime
          </p>
        </div>
        
        <div className="mb-8 p-6 bg-zinc-800 rounded-lg border border-zinc-700">
          <h3 className="text-xl font-semibold mb-4 flex items-center">
            <span className="mr-2">✨</span>
            Why Upgrade?
          </h3>
          <ul className="space-y-3">
            <li className="flex items-center">
              <span className="text-indigo-400 mr-2">•</span>
              <span>Extended conversations</span>
            </li>
            <li className="flex items-center">
              <span className="text-indigo-400 mr-2">•</span>
              <span>Early access to exciting new Dates</span>
            </li>
            <li className="flex items-center">
              <span className="text-indigo-400 mr-2">•</span>
              <span>Priority for new feature requests</span>
            </li>
          </ul>
        </div>

        <div className="space-y-4 mb-8">
          {['I', 'II', 'III'].map((plan) => (
            <button
              key={plan}
              className={`w-full p-4 rounded-lg ${
                loadingPlan === plan 
                  ? 'bg-gradient-to-r from-indigo-600 to-purple-600' 
                  : 'bg-zinc-800 hover:bg-zinc-700'
              } ${isPlanDisabled(plan) ? 'opacity-50 cursor-not-allowed' : ''} 
              transition-all duration-200 transform hover:scale-[1.02] border border-zinc-700`}
              onClick={() => !isPlanDisabled(plan) && handleUpgrade(plan)}
              disabled={isPlanDisabled(plan) || loadingPlan !== null}
            >
              {loadingPlan === plan ? (
                <div className="flex justify-center items-center h-full mt-1 mb-1">
                  <Spinner />
                </div>
              ) : (
                <div className="flex flex-col justify-center h-full mt-1 mb-1">
                  <div className="flex justify-between items-center">
                    <span className={`w-24 text-left font-medium ${
                      plan === 'I' ? 'text-amber-300' : plan === 'II' ? 'text-gray-400' : 'text-yellow-400'
                    }`}>
                      {plan === 'I' ? 'Basic' : plan === 'II' ? 'Advanced' : 'Elite'}
                    </span>
                    <span className="text-gray-400 font-medium">
                      {plan === 'I' ? '500' : plan === 'II' ? '1050' : '1650'} credits
                    </span>
                    <span className="w-24 text-right font-medium">
                      ${plan === 'I' ? '9.99' : plan === 'II' ? '19.99' : '29.99'}/mo
                      {isPlanDisabled(plan) && <span className="ml-2">✓</span>}
                    </span>
                  </div>
                  {plan !== 'I' && (
                    <div className="absolute top-0 right-0 bg-gradient-to-r from-indigo-600 to-purple-600 text-white text-[10px] font-bold px-2 py-1 rounded-bl-md rounded-tr-lg">
                      {plan === 'II' ? '5% off' : '10% off'}
                    </div>
                  )}
                </div>
              )}
            </button>
          ))}
        </div>

        {error && (
          <p className="text-red-500 mb-4 p-3 bg-red-500/10 rounded-lg border border-red-500/20">
            {error}
          </p>
        )}

        <div className="flex justify-between items-center space-x-4">
          <button
            className={`px-5 py-2.5 ${
              canClose 
                ? 'bg-zinc-700 hover:bg-zinc-600 text-white' 
                : 'bg-zinc-800 text-zinc-500 cursor-not-allowed'
            } rounded-lg transition-all duration-200 font-medium`}
            onClick={handleCloseModal}
            disabled={!canClose || loadingPlan !== null}
          >
            {canClose ? 'Close' : `Please wait... (${countdown}s)`}
          </button>
          {isPaidPlan && (
            <button
              className="px-6 py-3 bg-gradient-to-r from-red-600 to-pink-600 text-white font-medium rounded-lg hover:from-red-500 hover:to-pink-500 transition-all duration-200 transform hover:scale-105"
              onClick={handleCancelClick}
            >
              Cancel Subscription
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpgradePlanModal;
