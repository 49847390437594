import useUserStore from '../stores/userStore';

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || "";

// Get auth headers for all API requests
const getAuthHeaders = () => {
  const headers = {
    'Content-Type': 'application/json'
  };

  const accessToken = useUserStore.getState().accessToken;
  const visitorId = useUserStore.getState().visitorId;

  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  } else if (visitorId) {
    headers['x-visitor-id'] = visitorId;
  }

  return headers;
};

export const getOrCreateUser = async (auth0User, referralCode = '') => {
  const response = await fetch(API_DOMAIN + '/getOrCreateUser', {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({auth0User, referralCode}),
  });
  return await response.json();
};

export const acceptTOS = async () => {
  const response = await fetch(API_DOMAIN + '/acceptTOS', {
    method: 'POST',
    headers: getAuthHeaders(),
  });
  return await response.json();
};

export const fetchUsage = async () => {
  const response = await fetch(`${API_DOMAIN}/usage`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
  if (!response.ok) {
    throw new Error('Failed to fetch usage data');
  }
  return await response.json();
};

export const upgradePlan = async (plan) => {
  try {
    const response = await fetch(`${API_DOMAIN}/updatePlan`, {
      method: 'PUT',
      headers: getAuthHeaders(),
      body: JSON.stringify({ plan }),
    });
    return await response.json();
  } catch (error) {
    console.error('[user.js] Error during plan upgrade', { error: error.message });
    throw error;
  }
};

export const saveMicrophoneStatus = async (status) => {
  try {
    const response = await fetch(`${API_DOMAIN}/saveMicrophoneStatus`, {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify({ status }),
    });
    if (!response.ok) {
      throw new Error('Failed to save microphone status');
    }
    return await response.json();
  } catch (error) {
    console.error('[user.js] Error saving microphone status', { error: error.message });
    throw error;
  }
};

export const updateUserPreferences = async (preferences) => {
  try {
    const response = await fetch(`${API_DOMAIN}/preferences`, {
      method: 'PATCH',
      headers: getAuthHeaders(),
      body: JSON.stringify(preferences),
    });
    return await response.json();
  } catch (error) {
    console.error('Error updating user preferences:', error);
    throw error;
  }
};