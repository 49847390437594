import "../App.css";
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useState, useEffect } from "react";
import { usePersona } from "../contexts/PersonaClientContext";
import { getScenes } from "../scenes";
import Layout from "../components/Layout";
import Gallery from '../components/views/Gallery';
import useUserStore from '../stores/userStore';
import useModalStore from '../stores/modalStore';

const MICROPHONE_STATUSES = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

function Main() {
  const navigate = useNavigate();
  const history = createBrowserHistory();

  const {
    isPersonaLoading,
    isPersonaReady,
    startPersona,
    sceneState,
    setSceneState,
    selectedScene,
    setSelectedScene,
    endPersona
  } = usePersona();

  const openModal = useModalStore((state) => state.openModal);
  const saveMicrophoneStatus = useUserStore((state) => state.saveMicrophoneStatus);

  const isInitialized = isPersonaReady || isPersonaLoading;

  const user = useUserStore(state => state.user);
  const [scenes, setScenes] = useState(null);

  // Initialize scenes when user auth state changes
  useEffect(() => {
    if (user) {
      const isLoggedIn = !user.isAnonymous;
      setScenes(getScenes(isLoggedIn));
    }
  }, [user]);

  useEffect(() => {
    const unlisten = history.listen(({ action }) => {
      if (action === 'POP') {
        // If the user hits the back button, navigate to '/'
        endPersona();
        history.push('/');
      }
    });

    // Clean up the listener when the component unmounts
    return () => {
      unlisten();
    };
  }, [navigate, endPersona]);

  useEffect(() => {
    if (isPersonaReady && selectedScene && !window.location.pathname.includes('/story')) {
      navigate('/story');
    }
  }, [isPersonaReady, navigate, selectedScene, sceneState, isPersonaLoading]);

  const handleBegin = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach(track => track.stop());
      saveMicrophoneStatus(MICROPHONE_STATUSES.ENABLED);
      console.log('Starting persona');
      startPersona();
    } catch (error) {
      console.log('Error getting microphone access:', error);
      saveMicrophoneStatus(MICROPHONE_STATUSES.DISABLED);
      // Microphone access is disabled or denied
      openModal('microphone');
    }
  };

  useEffect(() => {
    if (selectedScene && !Object.keys(sceneState).length) {
      console.log('Setting scene state');
      setSceneState(scenes[selectedScene].defaultState);
    } else if (selectedScene && Object.keys(sceneState).length && !isInitialized) {
      console.log('Starting persona');
      handleBegin();
    }
  }, [selectedScene, sceneState, isInitialized]);

  return (
    <Layout>
      {scenes && (
        <Gallery
          scenes={scenes}
          selectedScene={selectedScene}
          setSelectedScene={setSelectedScene}
          isPersonaLoading={isPersonaLoading}
        />
      )}
    </Layout>
  );
}
export default Main;