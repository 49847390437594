import getPrompt from './prompt';
import getActions from './actions';

const getConfig = (isLoggedIn) => ({
  initialMessage: `Typical Betsy... inviting her friends over then running off... So, uh... I guess we're alone now...`,
  prompt: getPrompt(isLoggedIn),
  actions: getActions(isLoggedIn),
  ttsConfig: {
    voiceId: 'c34ae641-3afd-46cc-9dfd-671c148fba24'
  },
  llmConfig: {
    llm: 'llama-3-1-70b',
    temperature: 0.9,
    repetitionPenalty: 0.5,
  },
  interjections: {
    enabled: true,
    minWait: 5000,
    maxWait: 7500,
    thought: `There was just a pause; I should keep the conversation going.`
  },
  isLightning: true,
  limitReachedMessage: isLoggedIn ? `Darn, you're out of credits. Will you please upgrade your plan so we can keep going?` : `Why don't you create an account so I can help you relax a bit better?`
});

export default getConfig;
