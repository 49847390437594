import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useUserStore from '../stores/userStore';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const {
    isLoading: isAuth0Loading,
    error,
    user: auth0User,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    isAuthenticated,
  } = useAuth0();

  // console.log('++### isAuth0Loading', isAuth0Loading);
  // console.log('++### isAuthenticated', isAuthenticated);
  // console.log('++### auth0User', auth0User);
  // console.log('++### error', error);

  const { isLoading: isFingerprintLoading, data: visitorData } = useVisitorData();
  
  const setAccessTokenStore = useUserStore(state => state.setAccessToken);
  const initializeUser = useUserStore(state => state.initializeUser);
  const fetchAccountDetails = useUserStore(state => state.fetchAccountDetails);
  const setVisitorId = useUserStore(state => state.setVisitorId);
  const [accessToken, setAccessToken] = useState(null);

  
  useEffect(() => {
    
    const checkAuthentication = async () => {
      // console.log('### checkAuthentication ##');
      try {
        const referralCode = localStorage.getItem('referralCode');
        
        // Wait for both Auth0 and Fingerprint to finish loading
        if (isAuth0Loading || isFingerprintLoading) {
          return;
        }

        const currentUser = useUserStore.getState().user;
        
        // Clear existing user if auth state changes
        if (currentUser?.isAnonymous && isAuthenticated) {
          useUserStore.getState().setUser(null);
        }

        // Handle Auth0 errors by checking for email verification error
        if (error) {
          console.error('Auth0 error encountered:', error);
          
          // Check specifically for email verification error
          if (error.message === 'Please verify your email before continuing.') {
            useUserStore.getState().setShowVerificationMessage(true);
            return;
          }
        }

        // Set visitor ID if available
        if (visitorData) {
          setVisitorId(visitorData.visitorId);
        }

        // Proceed with initialization if authenticated
        if (isAuthenticated && auth0User && (!currentUser || currentUser.isAnonymous)) {
          // console.log('### initializing user from auth0');
          const accessToken = await getAccessTokenSilently();
          setAccessTokenStore(accessToken);
          setAccessToken(accessToken);
          await initializeUser(auth0User, referralCode);
          localStorage.removeItem('referralCode');
          fetchAccountDetails();
        } else if (
          visitorData && 
          !isAuthenticated && 
          !isAuth0Loading && 
          !currentUser
        ) {
          // Initialize anonymous user
          // console.log('### initializing anonymous user from Fingerprint');
          await initializeUser({
            sub: `anon|${visitorData.visitorId}`,
            email: null,
            isAnonymous: true
          }, referralCode, true);
          localStorage.removeItem('referralCode');
          fetchAccountDetails();
        }
      } catch (error) {
        console.error('Error during authentication check:', error);
      }
    };

    checkAuthentication();
  }, [
    isAuthenticated,
    isAuth0Loading,
    auth0User,
    isFingerprintLoading,
    visitorData,
    fetchAccountDetails,
    getAccessTokenSilently,
    initializeUser,
    setAccessTokenStore,
    setVisitorId
  ]);

  const contextValue = {
    isAuth0Loading,
    error,
    loginWithRedirect,
    logout,
    accessToken
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};