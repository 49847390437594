import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { analytics } from '../../util/analytics';

const SignUpButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = () => {
    analytics.track('Clicked Sign Up Button');
    loginWithRedirect();
  };

  return (
    <button
      onClick={handleSignUp}
      className="text-gray-400 hover:text-white font-medium text-sm flex items-center justify-center"
    >
      Sign Up
      <svg
        className="w-4 h-4 ml-2"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
        ></path>
      </svg>
    </button>
  );
};

export default SignUpButton;