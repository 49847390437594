const getActions = (isLoggedIn) => {
  const actions = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "type": "object",
    "_boosting": {
      "force": {
        "threshold": 0.98,
        "thought": "You MUST use the take the ***ACTION_NAME*** action here."
      },
      "encourage": {
        "threshold": 0.9,
        "thought": "You should consider the action ***ACTION_NAME***."
      }
    },
    "properties": {
      "change_emotion": {
        "_action_type": "update_speech",
        "_description": "Use this action to change emotions.",
        "_examples": [
          {
            "user says": "Be happy now",
            "assistant action includes": {
              "change_emotion": {
                "emotion": "happy"
              }
            }
          }
        ]
      },
      "change_setting": {
        "_description": "The setting you are moving to",
        "properties": {
          "change_setting": {
            "type": "string",
            "enum": [
              "library_study_room",
              "ashleys_dorm_room",
              "university_quad",
              "library_basement",
              "library_bathroom"
            ]
          }
        },
        "_examples": [
          {
            "user says": "Let's go back to your dorm room.",
            "assistant action includes": {
              "change_setting": "ashleys_dorm_room"
            }
          }
        ]
      }
    }
  };

  if (isLoggedIn) {
    actions.properties = {
      ...actions.properties,
      take_off_user_clothes: {
        "_description": "When you should disrobe",
        "_examples": [
          {
            "user says": "Take off your clothes",
            "assistant action includes": {
              "take_off_user_clothes": {
                "is_user_unclothed": true
              }
            }
          }
        ]
      },
      take_off_ashley_clothes: {
        "_description": "When you should disrobe",
        "_examples": [
          {
            "user says": "Take off your clothes",
            "assistant action includes": {
              "take_off_ashley_clothes": {
                "is_ashley_unclothed": true
              }
            }
          }
        ]
      },
      position: {
        "_description": "The sexual position or activity Ashley should engage in",
        "properties": {
          "position": {
            "type": "string",
            "enum": [
              "giving_blowjob",
              "titfuck", 
              "show_pussy_frontal",
              "show_pussy_closeup",
              "show_pussy_frontal_legs_up",
              "show_pussy_from_behind",
              "sex_reverse_cowgirl",
              "show_butt",
              "sex_missionary",
              "sex_doggystyle",
              "giving_handjob",
              "sitting"
            ]
          }
        },
        "_examples": [
          {
            "user says": "I want to fuck your tits.",
            "assistant action includes": {
              "position": "titfuck"
            }
          },
          {
            "user says": "Show me your pussy.",
            "assistant action includes": {
              "position": "show_pussy_frontal"
            }
          },
          {
            "user says": "Put your legs up for me.",
            "assistant action includes": {
              "position": "show_pussy_frontal_legs_up"
            }
          },
          {
            "user says": "Show me your pussy close up.",
            "assistant action includes": {
              "position": "show_pussy_closeup"
            }
          },
          {
            "user says": "Suck my dick now.",
            "assistant action includes": {
              "position": "giving_blowjob"
            }
          },
          {
            "user says": "Turn around, let me see that ass",
            "assistant action includes": {
              "position": "show_butt"
            }
          },
          {
            "user says": "I want to fuck you missionary style",
            "assistant action includes": {
              "position": "sex_missionary"
            }
          },
          {
            "user says": "Get on top of me",
            "assistant action includes": {
              "position": "sex_reverse_cowgirl"
            }
          },
          {
            "user says": "Let's do it doggystyle",
            "assistant action includes": {
              "position": "sex_doggystyle"
            }
          }
        ]
      },
      cum_location: {
        "_description": "When the user cums on ashley",
        "properties": {
          "cum_location": {
            "type": "string",
            "enum": [
              "cum on tits",
              "cum on hands",
              "cum in pussy",
              "cum in ass",
              "cum on ass", 
              "cum in mouth",
              "cum on face and glasses",
              "cum in hair",
              "cum on belly",
              "cum on back"
            ]
          }
        },
        "_examples": [
          {
            "user says": "I'm gonna cum on your tits",
            "assistant action includes": {
              "cum_location": "cum on tits"
            }
          },
          {
            "user says": "I'm coming in your pussy",
            "assistant action includes": {
              "cum_location": "cum in pussy"
            }
          },
          {
            "user says": "I'm pulling out to come",
            "assistant action includes": {
              "cum_location": "cum on belly"
            }
          }
        ]
      }
    };
  }

  return actions;
};

export default getActions;