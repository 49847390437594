import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import useUserStore from '../stores/userStore';
import { openModal } from '../stores/modalStore';
import RegularButton from "../components/buttons/RegularButton";
import Spinner from "../components/spinner";
import indigo_silhouette from '../assets/images/indigo_silhouette.png';
import { motion, AnimatePresence } from "framer-motion";
import ReportModal from "../components/modals/ReportModal";
import { analytics } from "../util/analytics";
const LoginButton = () => {
  const {
    isAuth0Loading,
    error,
    loginWithRedirect
  } = useAuth();

  const {
    user,
    isAccountLoading,
    showTermsOfService
  } = useUserStore();

  const navigate = useNavigate();
  const location = useLocation();

  const [didClickLogin, setDidClickLogin] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(true);
  const [shouldShowWelcome, setShouldShowWelcome] = useState(false);
  const [showAgeModal, setShowAgeModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const [showReportModal, setShowReportModal] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);



  useEffect(() => {
    analytics.page('Login');
  }, []);

  const handleDidClickLogin = () => {
    analytics.track('Clicked Login');
    setShowAgeModal(true);
  }

  const handleAgeConfirmation = () => {
    analytics.track('Clicked Age Confirmation');
    setShowAgeModal(false);
    localStorage.setItem('didClickLogin', true);
    setDidClickLogin(true);
    handleShowTOS();
    navigate('/');
  }

  const closeErrorModal = () => {
    analytics.track('Closed Error Modal');
    setShowErrorModal(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const error = params.get('error');
    const errorDescription = params.get('error_description');

    if (error && errorDescription) {
      console.error('Error during login:', error, errorDescription);
      setErrorMessage(decodeURIComponent(errorDescription));
      setShowErrorModal(true);
    }
  }, [location]);

  useEffect(() => {
    const didClickLoginFromStorage = localStorage.getItem('didClickLogin') === 'true';
    if (didClickLoginFromStorage && (isAuth0Loading || isAccountLoading)) {
      setAnimationComplete(false);
      setShouldShowWelcome(true);
    }
    
    const timer = setTimeout(() => {
      setIsInitialLoad(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, [isAuth0Loading, isAccountLoading]);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        if (!isAuth0Loading && !isAccountLoading && user && animationComplete) {
          if (user.hasAcceptedTOS && window.location.pathname === '/login') {
            analytics.track('Authenticated User');
            navigate('/');
          }
        }
      } catch (error) {
        analytics.track('Error during authentication check:', error);
        console.error('Error during authentication check:', error);
      }
    };

    checkAuthentication();
  }, [isAuth0Loading, user, isAccountLoading, navigate, animationComplete]);

  const titleVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  };

  const handleShowTOS = () => {
    showTermsOfService();
  };

  return (
    <div className="w-screen h-screen bg-main-bg flex flex-col justify-center items-center relative overflow-hidden">
      {
        (!isInitialLoad && (isAuth0Loading || isAccountLoading) && !shouldShowWelcome) ? <Spinner /> : 
          <>
            <div 
              className="absolute right-0 top-0 h-full w-1/3 bg-cover bg-left"
              style={{
                backgroundImage: `url(${indigo_silhouette})`,
                boxShadow: "-10px 0 10px rgba(0,0,0,0.5)"
              }}
            />
            <div className="z-10 flex flex-col items-center">
                <motion.div
                  initial="hidden"
                  animate="visible"
                  className="text-6xl font-bold text-white mb-4"
                  onAnimationComplete={() => setAnimationComplete(true)}
                >
                {
                  shouldShowWelcome ? (
                    ["Welcome", "back."].map((word, index) => (
                      <motion.span
                        key={word}
                        variants={titleVariants}
                        transition={{ duration: 0.5, delay: index * 0.5 }}
                        className="block"
                      >
                        {word}
                      </motion.span>
                    ))
                  ) : (
                    ["Date", "Night", "AI"].map((word, index) => (
                      <motion.span
                        key={word}
                        variants={titleVariants}
                        transition={{ duration: 0.5, delay: index * 0.5 }}
                        className="block"
                      >
                        {word}
                      </motion.span>
                    ))
                  )}
                </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 2 }}
                className="flex justify-start w-full"
              >
                {isAccountLoading || didClickLogin ? (
                  <Spinner />
                ) : (
                  <RegularButton
                    onClick={handleDidClickLogin}
                    className="w-auto"
                  >
                    Start
                  </RegularButton>
                )}
              </motion.div>
            </div>
          </>
      }
      <div className="absolute bottom-4 left-4 flex items-center space-x-2">
        <motion.a 
          href="mailto:support-alt@datenightai.com?subject=DateNight%20Support"
          className="text-gray-500 opacity-70 hover:text-white text-sm cursor-pointer"
          onClick={() => analytics.track('Clicked Support Link')}
        >
          Support
        </motion.a>
        <span className="text-gray-500 opacity-70">|</span>
        <motion.a 
          href="/2257.html" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-gray-500 opacity-70 hover:text-white text-sm"
          onClick={() => analytics.track('Clicked 2257 Exemption Link')}
        >
          18 U.S.C. 2257 Record-Keeping Requirements Exemption Statement
        </motion.a>
        <span className="text-gray-500 opacity-70">|</span>
        <motion.a 
          href="/privacypolicy.html" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-gray-500 opacity-70 hover:text-white text-sm"
          onClick={() => analytics.track('Clicked Privacy Policy Link')}
        >
          Privacy Policy
        </motion.a>
        <span className="text-gray-500 opacity-70">|</span>
        <motion.span 
          onClick={() => {
            analytics.track('Clicked Report Content Link');
            openModal('report');
          }}
          className="text-gray-500 opacity-70 hover:text-white text-sm cursor-pointer"
        >
          Report Content
        </motion.span>
        <span className="text-gray-500 opacity-70">|</span>
        <motion.a 
          href="https://epoch.com" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-gray-500 opacity-70 hover:text-white text-sm"
          onClick={() => analytics.track('Clicked Billing Support Link')}
        >
          Billing Support
        </motion.a>
      </div>

      <AnimatePresence>
        {showAgeModal && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={modalVariants}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <div className="bg-zinc-900 p-8 rounded-lg max-w-md text-white">
              <h2 className="text-2xl font-bold mb-4">Adult Content Warning</h2>
              <p className="mb-6">
                WARNING: This website contains adult content. Only proceed if you are 18 years of age and/or are legally eligible to view such content in your jurisdiction.
              </p>
              <div className="flex justify-between">
                <button 
                  onClick={() => {
                    analytics.track('Clicked Cancel Age Confirmation');
                    setShowAgeModal(false);
                    window.location.href = 'https://www.google.com';
                  }}
                  className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700"
                >
                  Cancel
                </button>
                <RegularButton onClick={handleAgeConfirmation}>
                  I am at least 18 years of age, enter
                </RegularButton>
              </div>
            </div>
          </motion.div>
        )}

        {showErrorModal && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={modalVariants}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <div className="bg-zinc-900 p-8 rounded-lg max-w-md text-white">
              {errorMessage === "Please verify your email before continuing." ? (
                <>
                  <h2 className="text-2xl font-bold mb-4">Email Verification</h2>
                  <p className="mb-6">Please check your email and click the verification link to complete your registration.</p>
                </>
              ) : (
                <>
                  <h2 className="text-2xl font-bold mb-4">Login Error</h2>
                  <p className="mb-6">{errorMessage}</p>
                </>
              )}
              <div className="flex justify-end">
                <RegularButton onClick={closeErrorModal}>
                  Close
                </RegularButton>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {/* {showReportModal && <ReportModal onClose={() => setShowReportModal(false)}/>} */}
    </div>
  );
};

export default LoginButton;
