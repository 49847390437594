import React, { useEffect, useRef, memo, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useAuth } from '../../contexts/AuthContext';
import { usePersona } from '../../contexts/PersonaClientContext';
import { generateImage } from '../../api/image';
import Layout from '../Layout';
import { getModalStates, openModal, closeModal } from '../../stores/modalStore';
import useUserStore from '../../stores/userStore';
import { analytics } from '../../util/analytics';

const history = createBrowserHistory();

const MessagesList = memo(({ messages }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    analytics.page('Story');
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  return (
    <div className="space-y-4 mb-2">
      {messages.map((message, index) => (
        <motion.div
          key={index}
          className={`flex ${message.source === 'ai' ? 'flex-col items-start' : 'flex-col items-end'} space-y-2`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.35 }}
        >
          <div
            className={`w-auto px-4 py-3 text-sm ${
              message.source === 'ai' ?
              'rounded-bl-none bg-zinc-700 text-white' :
              `rounded-br-none ${message.type === 'temporary_message' ? 'opacity-75' : ''} bg-indigo-600 text-white`
            } max-w-3/4 rounded-xl shadow`}
          >
            {message.content}
          </div>
        </motion.div>
      ))}
    <div ref={messagesEndRef} className="pt-2" />
    </div>
  );
});

const ImageControls = memo(({ isNewImageLoading, setShouldRegenerateImage, endPersona }) => {
  return (
  <div className="flex justify-between items-center w-full mb-10 mt-2 lg:mb-4 mb-12">
    <button
      className="text-indigo-600 bg-transparent border border-indigo-600 hover:bg-indigo-600 hover:text-white focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      onClick={() => {
        analytics.track('Clicked End Chat');
        endPersona();
      }}
    >
      End Chat
    </button>
    {isNewImageLoading ? (
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <svg 
          className="w-10 h-10 text-white animate-spin" 
          xmlns="http://www.w3.org/2000/svg" 
          fill="none" 
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </motion.div>
    ) : (
      <div className="flex items-center space-x-2">
        <div className="relative group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            viewBox="0 0 16 16"
            className="text-gray-500 hover:text-gray-300 opacity-70 hover:opacity-100 transition-opacity cursor-pointer"
            onClick={() => {
              analytics.track('Clicked Report Button');
              openModal('report');
            }}
          >
            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
          </svg>
          <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-zinc-900 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            Report
          </span>
        </div>
        <motion.button
          className="text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          onClick={() => {
            analytics.track('Clicked Regenerate Image');
            setShouldRegenerateImage(true);
          }}
        >
          Regenerate
        </motion.button>
      </div>
    )}
  </div>
  )
});

const Story = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accessToken } = useAuth();
  const {
    isPersonaReady,
    isPersonaLoading,
    selectedScene,
    sceneState,
    messages,
    endPersona
  } = usePersona();

  const { isLimitReached } = useUserStore();
  const [base64Image, setBase64Image] = useState('');
  const [isNewImageLoading, setIsNewImageLoading] = useState(false);
  const [shouldRegnerateImage, setShouldRegenerateImage] = useState(false);
  const [shouldRegnerateImageTimer, setShouldRegenerateImageTimer] = useState(false);
  const [regenerateTimer, setRegenerateTimer] = useState(null);
  const [imageId, setImageId] = useState('');
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const handleEndPersona = () => {
    clearTimeout(regenerateTimer);
    endPersona();
    navigate('/');
  };

  /* BEGIN Redirect to home if the user reloads the page */

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem('reloaded', 'true');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    const reloaded = sessionStorage.getItem('reloaded');
    if (reloaded === 'true') {
      setShouldRedirect(true);
      sessionStorage.removeItem('reloaded');
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  if (shouldRedirect) {
    navigate('/');
  }

  /* END Redirect to home if the user reloads the page */

  /* BEGIN go back to main if the user goes back */
  useEffect(() => {
    const unlisten = history.listen(({ action }) => {
      if (action === 'POP') {
        // If the user hits the back button, navigate to '/'
        history.push('/');
        handleEndPersona();
      }
    });

    // Clean up the listener when the component unmounts
    return () => {
      unlisten();
    };
  }, [history, handleEndPersona]);

  /* END go back to main if the user goes back */

  const isInitialized = isPersonaReady || isPersonaLoading;

  const startRegenerateTimer = () => {
    const timer = setTimeout(() => {
      if (!isNewImageLoading) {
        setShouldRegenerateImageTimer(true);
      }
    }, 20000); // 20000 milliseconds = 20 seconds
    setRegenerateTimer(timer);
  };


  const regenerateImage = async (currentSceneState) => {
    try {
      console.log('SELECTED SCENE', selectedScene);
      console.log('CURRENT SCENE STATE', currentSceneState);
      const { image : newImage , imageId : newImageId }  = await generateImage(selectedScene, currentSceneState);
      setBase64Image(newImage);
      setImageId(newImageId);
    } catch (error) {
      console.error('Error generating image:', error);
      
      // TODO this isn't handling properly
      if (error.message && error.message.toLowerCase().includes('Insufficient usage remaining')) {
        openModal('upgradePlan');
      }
    }
  };
  
  useEffect(() => {
    const generateNewImage = async () => {
      if (isInitialized) {
        resetRegenerateTimer();
        setIsNewImageLoading(true);
        await regenerateImage(sceneState);
        setIsNewImageLoading(false);
      }
    };
    generateNewImage();
  }, [isInitialized, sceneState]);

  useEffect(() => {
    const generateNewImage = async () => {
      if (isInitialized) {
        resetRegenerateTimer();
        setIsNewImageLoading(true);
        await regenerateImage(sceneState);
        setIsNewImageLoading(false);
      }
    };

    if (shouldRegnerateImage) {
      setShouldRegenerateImage(false);
      generateNewImage();
    }
  }, [isInitialized, sceneState, shouldRegnerateImage]);

  useEffect(() => {
    const generateNewImage = async () => {
      if (isInitialized) {
        resetRegenerateTimer();
        setIsNewImageLoading(true);
        await regenerateImage(sceneState);
        setIsNewImageLoading(false);
      }
    };

    if (shouldRegnerateImageTimer) {
      setShouldRegenerateImageTimer(false);
      generateNewImage();
    }
  }, [isInitialized, sceneState, shouldRegnerateImageTimer]);

  useEffect(() => {
    if (isInitialized) {
      startRegenerateTimer();
    }
    return () => {
      if (regenerateTimer) {
        clearTimeout(regenerateTimer);
      }
    };
  }, []);

  const resetRegenerateTimer = () => {
    if (regenerateTimer) {
      clearTimeout(regenerateTimer);
    }
    startRegenerateTimer();
  };

  useEffect(() => {
    if (isLimitReached) {
      analytics.track('Reached Usage Limit');
      openModal('upgradePlan');
    }
  }, [isLimitReached]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  return (
    <Layout>
      <div className="flex flex-col lg:flex-row w-full h-full lg:pt-12" style={{paddingTop: '3rem'}}>
        <div className='flex flex-col w-full lg:w-1/5 h-95% px-4 pt-4 pb-2 overflow-y-auto space-y-4 bg-main-bg bg-opacity-70 rounded-lg lg:my-12 lg:ml-4 lg:mr-2'>
          <MessagesList messages={messages} />
        </div>
        {base64Image && (
          <div className='flex-grow flex flex-col justify-center items-center pt-6 lg:m-2 lg:w-4/5'>
            <div className="flex flex-col justify-center items-center w-auto mx-auto">
              <motion.img
                key={base64Image}
                src={base64Image}
                alt="Generated Content"
                className="max-w-full max-h-[85vh] w-auto h-auto rounded-lg"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              />
              <ImageControls
                isNewImageLoading={isNewImageLoading}
                setShouldRegenerateImage={setShouldRegenerateImage}
                endPersona={handleEndPersona}
              />
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}
export default Story;
