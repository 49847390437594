import { create } from 'zustand';
import { fetchUsage, getOrCreateUser, acceptTOS as acceptTOSApi, upgradePlan as upgradePlanApi, saveMicrophoneStatus as saveMicrophoneStatusApi, updateUserPreferences } from '../api/user';
import { analytics } from '../util/analytics';
const useUserStore = create((set, get) => ({
  user: null,
  accountDetails: null,
  isAccountLoading: false,
  accessToken: null,
  isLimitReached: false,
  showTOS: false,
  shouldSaveTranscript: true,
  visitorId: null,
  forceShowTOS: false,
  showVerificationMessage: false,

  setAccessToken: (token) => set({ accessToken: token }),

  setUser: (user) => set({ user }),

  setVisitorId: (id) => set({ visitorId: id }),

  fetchAccountDetails: async () => {
    set({ isAccountLoading: true });
    try {
      const data = await fetchUsage();
      set({ 
        accountDetails: data, 
        isAccountLoading: false,
        isLimitReached: data.usageRemaining <= 0.05
      });
    } catch (err) {
      console.error('Error fetching usage:', err);
      set({ isAccountLoading: false });
    }
  },

  initializeUser: async (auth0User, referralCode, silent = false) => {
    try {
      const { visitorId } = get();
      
      const currentUser = get().user;
      if (currentUser?.sub === auth0User?.sub) {
        return currentUser;
      }

      const user = await getOrCreateUser(auth0User, referralCode, visitorId);

      if ((auth0User.isAnonymous && user.isAnonymous) || 
          (!auth0User.isAnonymous && !user.isAnonymous)) {
        analytics.identify(user._id, {
          isAnonymous: user.isAnonymous,
          visitorId: visitorId
        });

        const showVerification = auth0User?.email_verified === false;
        
        set({ 
          user,
          showTOS: !user.hasAcceptedTOS && window.location.pathname !== '/login',
          shouldSaveTranscript: user.shouldSaveTranscript ?? true,
          showVerificationMessage: showVerification
        });
      }
      
      return user;
    } catch (error) {
      console.error('Error initializing user:', error);
    }
  },

  acceptTOS: async () => {
    try {
      const response = await acceptTOSApi();
      console.log('response to acceptTOS', response)
      if (response.message === 'TOS accepted successfully.') {
        console.log('setting user to have accepted TOS')
        set(state => ({ 
          user: { ...state.user, hasAcceptedTOS: true },
          showTOS: false,
          forceShowTOS: false
        }));
      }
    } catch (error) {
      console.error('Error accepting TOS:', error);
    }
  },

  upgradePlan: async (plan) => {
    try {
      return await upgradePlanApi(plan);
    } catch (error) {
      throw error;
    }
  },

  saveMicrophoneStatus: async (status) => {
    try {
      return await saveMicrophoneStatusApi(status);
    } catch (error) {
      throw error;
    }
  },

  toggleSaveTranscript: async () => {
    const { shouldSaveTranscript } = get();
    const newValue = !shouldSaveTranscript;
    try {
      set({ shouldSaveTranscript: newValue });
      await updateUserPreferences({ shouldSaveTranscript: newValue });
    } catch (error) {
      console.error('Error updating transcript saving preference:', error);
      set({ shouldSaveTranscript: !newValue });
    }
  },

  setIsLimitReached: (value) => set({ isLimitReached: value }),

  showTermsOfService: () => set({ forceShowTOS: true }),
  hideTermsOfService: () => set({ forceShowTOS: false }),

  setShowVerificationMessage: (show) => set({ showVerificationMessage: show }),
}));

export default useUserStore;
