import _ from 'lodash';
import getPersonaConfig from './persona';
import nicole_thumbnail from '../../assets/images/nicole_thumbnail.png';

let nicoleStateChangesSinceCum = 0;

const getNicole = (isLoggedIn) => ({
  personaConfig: getPersonaConfig(isLoggedIn),
  thumbnail: nicole_thumbnail,
  sdModel: "picxReal_10",
  defaultState: {
    isNicoleNaked: false,
    update_position: 'sitting',
    setting: 'office_corner_room',
    cumLocations: []
  },
  handleAction: (prevState, detail) => {
    const newNicoleState = { ...prevState };
  
    if (detail.take_off_nicole_clothes && !prevState.isNicoleNaked) {
      newNicoleState.isNicoleNaked = true;
    }

    if (detail.update_position && detail.update_position !== prevState.update_position) {
      newNicoleState.update_position = detail.update_position;
    }

    if (detail.change_setting && detail.change_setting !== prevState.setting) {
      newNicoleState.setting = detail.change_setting;
    }

    if (detail.cum_location && !_.includes(prevState.cumLocations, detail.cum_location)) {
      newNicoleState.cumLocations = [...newNicoleState.cumLocations, detail.cum_location];
    }

    if (
      newNicoleState.isNicoleNaked !== prevState.isNicoleNaked ||
      newNicoleState.update_position !== prevState.update_position ||
      newNicoleState.setting !== prevState.setting ||
      newNicoleState.cumLocations.length > prevState.cumLocations.length
    ) {
      nicoleStateChangesSinceCum++;
      if (nicoleStateChangesSinceCum > 2 && !(newNicoleState.cumLocations.length > prevState.cumLocations.length)) {
        newNicoleState.cumLocations = [];
        nicoleStateChangesSinceCum = 0;
      }
      return newNicoleState;
    }

    return prevState;
  },
  generateSceneSilenceReaction: (sceneState) => {
    let clothingStateDescription = sceneState.isNicoleNaked ? `I'm naked` : `I still have my clothes on`;
    let settingPrompt =
      sceneState.setting === 'office_corner_room' ? `in my corner office at my Chicago skyscraper law firm` :
      sceneState.setting === 'office_janitor_closet' ? `in a dark janitor's closet of my Chicago skyscraper law firm` : ''

    let positionStateDescription =
      sceneState.update_position === 'sitting' ? `I'm sitting at my desk, directly across from him` :
      sceneState.update_position === 'titfuck' ? `I'm titfucking him` :
      sceneState.update_position === 'giving_handjob' ? `I'm giving him a handjob` :
      sceneState.update_position === 'giving_blowjob' ? `I'm giving him a blowjob` :
      sceneState.update_position === "show_pussy_frontal" ? `I'm showing him my pussy` :
      sceneState.update_position === "show_pussy_closeup" ? `I'm showing him a closeup my pussy` :
      sceneState.update_position === "show_pussy_frontal_legs_up" ? `I'm showing him my pussy with my legs up` :
      sceneState.update_position === "show_pussy_from_behind" ? `I'm showing him my pussy from behind` :
      sceneState.update_position === "sex_reverse_cowgirl" ? `I'm fucking him in reverse cowgirl position, riding his cock` :
      sceneState.update_position === "face_closeup" ? `my face is close up to his` :
      sceneState.update_position === 'sex_missionary' ? `he's fucking me in missionary position` :
      sceneState.update_position === 'sex_doggystyle' ? `he's fucking me doggystyle` :
      sceneState.update_position === 'show_butt' ? `I'm standing in front of him, showing him my butt` :
      ''
      return `He hasn't said anything in a bit. I'm going to take the lead, especially given that ${clothingStateDescription} and ${positionStateDescription} and we're ${settingPrompt}`;
  }
});

export default getNicole;