import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { PersonaProvider } from './contexts/PersonaClientContext';
import ProtectedRoute from './components/util/ProtectedRoute';
import TermsOfServiceModal from './components/modals/ToS';
import ReferralHandler from './components/util/ReferralHandler';
import Main from './pages/Main';
import Login from './pages/Login';
import Story from './components/views/Story';
import ModalManager from './components/ModalManager';
import useUserStore from './stores/userStore';

function AppContent() {
  const showTOS = useUserStore(state => state.showTOS);
  const forceShowTOS = useUserStore(state => state.forceShowTOS);
  const acceptTOS = useUserStore(state => state.acceptTOS);
  const user = useUserStore(state => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const showVerificationMessage = useUserStore(state => state.showVerificationMessage);
  const setShowVerificationMessage = useUserStore(state => state.setShowVerificationMessage);

  useEffect(() => {
    if (user && !user.hasAcceptedTOS && window.location.pathname !== '/login') {
      navigate('/login');
    }
  }, [user, navigate]);

  useEffect(() => {
    return () => {
      if (location.pathname !== '/login') {
        setShowVerificationMessage(false);
      }
    };
  }, [location.pathname, setShowVerificationMessage]);

  return (
    <>
      {(showTOS || forceShowTOS) && <TermsOfServiceModal onAccept={acceptTOS} />}
      <ModalManager />
      <Routes>
        <Route path="/login" element={<Login key="login" />} />
        <Route 
          path="/story" 
          element={
            user?.hasAcceptedTOS ? <Story key="story" /> : <Navigate to="/login" replace />
          } 
        />
        <Route 
          path="/" 
          element={
            user?.hasAcceptedTOS ? <Main key="main" /> : <Navigate to="/login" replace />
          }
        />
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
        <AuthProvider>
          <PersonaProvider>
            <ReferralHandler>
              <AppContent />
            </ReferralHandler>
          </PersonaProvider>
        </AuthProvider>
    </Router>
  );
}

export default App;